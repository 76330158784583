body {
    margin: 0;
    font-family: 'Helvetica Neue',sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    color: #54555a;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn{
    border-radius: 4px;
}

.ant-row{
    width: 100%;
}

.ant-table-thead tr th{
    background-color: #3f82b1 !important;
    color: white !important;
}

.ant-tabs-tab-btn{
    font-size: 22px;
    color: grey;
    font-weight: 500;
}

.ant-input, .ant-select, .ant-picker, .ant-input-number{
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ant-select-selector{
    border-radius: 4px !important;
}

.ant-modal, .ant-form{
    color: #54555a;
}

.ant-form-item{
    margin-bottom: 5px;
}

.ant-modal-content{
    border-radius: 5px;
}

.ant-modal-header{
    background: #3db27a;
    border-radius: 5px 5px 0 0;
}

.ant-modal-title{
    color: white;
    text-align: center;
}

.ant-input-affix-wrapper {
    border-radius: 4px;
}

.badge{
    background: lightgrey;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 2px 10px;
    margin: 0 10px;
    cursor: help;
}

.badge-info{
    background-color: #3f82b1 !important;
    color: white;
}

.blue-btn{
    background: #428bca;
    color: white;
    border: none;
}

.blue-btn:hover, .blue-btn:focus{
    background: #4294d3;
    color: white;
    border: none;
}

.orange-btn{
    background: #f0ad4e;
    color: white;
    border: none;
}

.orange-btn:hover, .orange-btn:focus{
    background: #f0ad4e;
    color: white;
    border: none;
}

label{
    font-weight: bold;
    color: #54555a;
}

label.ant-radio-wrapper{
    font-weight: bold;
    margin: 10px 0;
    font-size: 12px;
}

.text-success{
    color: #3db27a;
}